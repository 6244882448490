import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  CircularProgress,
  Grid,
  Tabs,
  Tab
} from "@mui/material";
import ContentBlock from "components/ContentBlock";
import useApplication from "hooks/useApplication";
import useUser from "hooks/useUser";
import useBreadcrumb from "hooks/useBreadcrumb";
import {
  ChangePassword,
  AccountDetails,
  TasksColor,
  MfaSetup,
  RingCentralAccount,
} from 'components/UserProfile';
import History from "components/UserProfile/History";
import useAccessControl from "hooks/useAccessControl";

export default function View() {
  const { setPage } = useApplication();
  const { setBreadcrumbCustomPlace } = useBreadcrumb();
  const { getUser } = useUser();
  const { uuid } = useParams();
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState({});
  const [selectedTab, selectTab] = React.useState(0);
  const { canUpdateUser } = useAccessControl();

  useEffect(() => {
    setBreadcrumbCustomPlace("");
    setPage({
      title: "Users",
      path: "/app/users",
    });
    setLoading(true);
    getUser(uuid)
      .then((resp) => {
        if (resp?.data !== undefined) {
          setUser(resp.data);
          setBreadcrumbCustomPlace(
            `${resp?.data?.first_name || ""} ${resp?.data?.last_name || ""}`
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line
    return () => {
      setBreadcrumbCustomPlace(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleMFAChange = (mfa) => {
    setUser({
      ...user,
      mfa,
    });
  }
  return (
    <ContentBlock title="Users" icon="ion-ios-clipboard-outline">
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress color="primary" size={40} />
        </Box>
      ) : (
        <Grid container spacing={4}>
          <Grid item md={2}>
            <Tabs
              orientation="vertical"
              value={selectedTab}
              onChange={(_event, newValue) => selectTab(newValue)}
              sx={{ borderRight: 1, borderColor: 'divider', height: '100%' }}
            >
              <Tab value={0} label="Account details" />
              {canUpdateUser() && <Tab value={1} label="Change password" />}
              <Tab value={2} label="Tasks colors" />
              <Tab value={3} label="MFA setup" />
              <Tab value={4} label="History" />
              <Tab value={5} label="RingCentral Account" />
              {/* <Tab value={6} label="QuickBooksConnect" /> */}
            </Tabs>
          </Grid>
          <Grid item md={10}>
            {selectedTab === 0 && <AccountDetails user={user} readOnly={!canUpdateUser()} />}
            {selectedTab === 1 && canUpdateUser() && <ChangePassword user={user} />}
            {selectedTab === 2 && <TasksColor user={user} readOnly={!canUpdateUser()} />}
            {selectedTab === 3 && <MfaSetup users={user} onChangeCallback={handleMFAChange} readOnly={!canUpdateUser()} />}
            {selectedTab === 4 && <History user={user} />}
            {selectedTab === 5 && <RingCentralAccount user={user} readOnly={!canUpdateUser()}/>}
            {/* {selectedTab === 6 && <QuickBooksConnect user={user} readOnly={!canUpdateUser()}/>} */}
          </Grid>
        </Grid>
      )}
    </ContentBlock>
  );
}
