import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import FormField from "components/FormField";
import JobCategoryPicker from "components/Inputs/JobCategoryPicker";
import JobCodePicker from "components/Inputs/JobCodePicker";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import JobEstimates from "components/JobSheetModal/components/JobEstimates";
import useAccessControl from "hooks/useAccessControl";
import Dispatched from "components/CreateJobModal/components/Dispatched";

const JobDetails = ({
  category_id,
  code_id,
  description,
  setJob,
  getData,
  state,
  job,
  changeJob,
  removeContractor,
}) => {
  const { canReadJobEstimate } = useAccessControl();
  const [estimatesPanel, setEstimatesPanel] = useState("estimate");
  const [contractorPanel, setContractorPanel] = useState("contractor");
  const handleEstimatePanelChange = (_event, isExpanded) => {
    setEstimatesPanel(isExpanded ? "estimate" : false);
  };
  const handleContractorPanelChange = (_event, isExpanded) => {
    setContractorPanel(isExpanded ? "contractor" : false);
  };

  return (
    <Grid container spacing={2}>
      <Grid item sm={12} md={4}>
        <JobCategoryPicker
          value={category_id}
          handleChange={(e) => setJob({ category_id: e.target.value })}
        />
      </Grid>
      <Grid item sm={12} md={8}>
        <JobCodePicker
          category_id={category_id}
          value={code_id}
          handleChange={(e) => setJob({ code_id: e.target.value })}
        />
      </Grid>
      <Grid item sm={12}>
        <Typography
          marginTop={2}
          gutterBottom
          borderBottom={"solid 1px #ddd"}
          variant="subtitle2"
        >
          Job Details<sup>*</sup>
        </Typography>
        <FormField
          required
          value={description || ""}
          onChange={(e) => setJob({ description: e.target.value })}
          placeholder="Add job details"
          multiline
          rows={4}
        />
        {state.id != null && canReadJobEstimate() && (
          <Accordion
            expanded={estimatesPanel === "estimate"}
            onChange={handleEstimatePanelChange}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography fontWeight={"bold"}>Estimates</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Divider />
              <JobEstimates
                open
                job_id={job?.id}
                job={job}
                estimate={job?.estimate}
                onAccepted={getData}
              />
            </AccordionDetails>
          </Accordion>
        )}
        {(job.contractor || job.status === "DISPATCH") && (
          <Accordion
            expanded={contractorPanel === "contractor"}
            onChange={handleContractorPanelChange}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography fontWeight={"bold"}>
                Contractor {!job.dispatched && <>(Dispatch Request Pending)</>}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Divider />
              <Dispatched
                job={job}
                removeContractor={removeContractor}
                onSubmit={(data) => changeJob(data)}
              />
            </AccordionDetails>
          </Accordion>
        )}
      </Grid>
    </Grid>
  );
};

export default JobDetails;
