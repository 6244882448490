import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
// Styles
import dayjs from "dayjs";
import { Button, Chip } from "@mui/material";
import { statuses } from "utils/jobStatuses";
import columns from "./tableData";
import useStyles from "./styles";

const MembershipJobs = ({ jobs, openView }) => {
  const classes = useStyles();
  const [jobData, setJobData] = useState([]);

  useEffect(() => {
    const newJobs = [];
    if (jobs.length > 0) {
      for (let job in jobs) {
        newJobs.push({
          id: jobs[job]?.id,
          name: jobs[job].category?.name,
          contractor: jobs[job]?.contractor
            ? jobs[job]?.contractor?.name
            : "Pending",
          date: dayjs(jobs[job]?.created_at)?.format("MM/DD/YYYY"),
          dateInv: "-",
          amount: "-",
          description: jobs[job]?.code?.description,
          invoice: "Pending",
          dateRecorded: dayjs(jobs[job]?.created_at).format("MM/DD/YYYY"),
          status: jobs[job]?.status,
          job: jobs[job],
          jobInvoiceUrl: jobs[job]?.jobInvoiceUrl,
        });
      }
      setJobData(newJobs);
    }
  }, [jobs]);

  const renderStatus = (data) => {
    return (
      <Chip
        sx={{
          background: statuses[data.row.status],
          color: "white",
        }}
        label={data.row.status}
      />
    );
  };

  const handleClick = (data) => {
    const templateParams = {
      to_email: data?.row?.job?.contact?.email, // Add recipient email
      subject: "Job Invoice PDF",
      body: `Please find the attached job invoice at the following link: ${data?.row?.job?.jobInvoiceUrl}`,
    };
    const mailtoUrl = `mailto:${
      templateParams?.to_email
    }?subject=${encodeURIComponent(
      templateParams?.subject
    )}&body=${encodeURIComponent(templateParams?.body)}`;
    window.open(mailtoUrl, "_blank");
  };

  const renderAction = (data) => (
    <div>
      <Button size="small" onClick={() => openView(data?.row?.job)}>
        View
      </Button>
      <Button
        onClick={() => handleClick(data)}
        size="small"
      >
        Email
      </Button>
    </div>
  );

  return (
    <React.Fragment>
      <DataGrid
        rows={jobData}
        columns={columns(renderStatus, renderAction)}
        pageSize={13}
        rowsPerPageOptions={[13]}
        disableSelectionOnClick
        autoHeight
        autoPageSize
        className={classes.dataGrid}
        density="compact"
      />
    </React.Fragment>
  );
};

export default MembershipJobs;
