const columns = (renderAction) => [
  {
    field: "region_code",
    headerName: "ID",
    width: 300,
  },
  {
    field: "office",
    headerName: "Office",
    width: 300,
    editable: true,
  },
  {
    field: "assigned",
    headerName: "Assigned",
    width: 300,
    editable: true,
  },
  {
    field: "enabled",
    headerName: "Enabled",
    type: "string",
    width: 300,
    editable: true,
    renderCell: (params) => (
      <span>{params.row.enabled ? 'Yes' : 'No'}</span>
    ),
  },
  {
    field: "action",
    headerName: "Action",
    sortable: false,
    width: 330,
    renderCell: renderAction,
  },
];
export default columns;
