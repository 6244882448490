import { Avatar, Chip } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
const defaultUser = {
    bgColor: "lightgray",
    txtColor: "black",
    name: "Unassigned",
};
const columns = (renderAction) => [
    {
        field: 'id',
        headerName: 'ID',
        width: 90
    },
    {
        field: 'name',
        headerName: 'Name',
        width: 300,
        editable: true,
        renderCell: (params) => (
                <Chip
                    sx={{
                        justifyContent: "flex-start",
                        background: params.row.bgColor ?? defaultUser.bgColor,
                        color: params.row.txtColor ?? defaultUser.txtColor,
                    }}
                    avatar={
                        <Avatar size="small">
                            <PersonIcon sx={{ color: "#fff" }} />
                        </Avatar>
                    }
                    label={params.row.name ?? defaultUser.name}
                />
        ),
    },
    {
        field: 'email',
        headerName: 'Email',
        width: 300,
        editable: true,
    },
    {
        field: 'role',
        headerName: 'Role',
        width: 300,
        editable: true,
    },
    {
        field: 'extension',
        headerName: 'Ext.',
        sortable: false,
        width: 220,
    },
    {
        field: "action",
        headerName: "Action",
        type: "string",
        width: 200,
        sortable: false,
        renderCell: renderAction,
    },
];
export default columns;   
