import React, { useEffect, useState } from "react";
import { Box, Tab } from "@mui/material";
import useApplication from "hooks/useApplication";
import PaperBlock from "components/PaperBlock";
import SearchInput from "components/SearchInput";
import useJobs from "hooks/useJobs"; // Assuming useJobs will be used for estimates
import { TabContext, TabList, TabPanel } from "@mui/lab";
import EstimatesData from "./components/EstimatesData";

export default function Estimates() {
  const [tabValue, setTabValue] = useState("0");
  const { setPage } = useApplication();
  const {
    jobsReducer: { query },
    getJobsData,  // TODO FIX
  } = useJobs();

  const fetchEstimates = () => {
    getJobsData(query); // TODO FIX
  };

  useEffect(() => {
    setPage({
      title: "Estimates Directory",
      path: "/app/estimates",
    });
    fetchEstimates();
    // eslint-disable-next-line
  }, []);

  const handleSearch = (value) => {
    getJobsData(value); // TODO FIX
    // searchJobs(value);
  };

  return (
    <PaperBlock
      title="Estimates"
      actions={
        <>
          <SearchInput
            defaultValue={query}
            onChange={handleSearch}
            placeholder="By ID, First Name, Last Name, Phone or Email"
          />
        </>
      }
    >
      <Box sx={{ flexGrow: 1, bgcolor: "background.paper" }}>
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={(_event, newValue) => setTabValue(newValue)}>
              <Tab
                label="Estimates"
                value="0"
              />
            </TabList>
          </Box>
          <TabPanel value="0">
            <EstimatesData />
          </TabPanel>
        </TabContext>
      </Box>
    </PaperBlock>
  );
}
