import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import "../../utils/sweetalert.css";
import {
  Grid,
  MenuItem,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import ".././../utils/sweetalert.css";
import { makeStyles } from "@mui/styles";
import Swal from "sweetalert2";
import { useTheme } from "@mui/material/styles";
import FormField from "components/FormField";
import { statuses, statusList } from "utils/jobStatuses";
import { viewMembershipRequest } from "api/membership";
import ApiSelect from "components/ApiSelect";
import { getUsersRequest } from "api/users";
import { createJobRequest } from "api/jobs";
import QModal from "components/QModal";
import JobDetails from "./components/JobDetails";
import Members from "./components/Members";
import Property from "./components/Property";

const useStyles = makeStyles(() => ({
  modalHeader: {
    borderBottom: "solid 1px #ddd",
  },
  modalTitle: {
    padding: 0,
  },
  modalSubtitle: {
    color: "gray",
  },
  dialogContent: {
    overflow: "auto",
  },
  actionsLabel: {
    color: "gray",
  },
  whited: {
    "& div": {
      color: "white",
    },
  },
  COMPLETED: { background: statuses.COMPLETED },
  DISPATCH: { background: statuses.DISPATCH },
  ESTIMATE: { background: statuses.ESTIMATE },
  SIGNED: { background: statuses.SIGNED },
  CANCELLED: { background: statuses.CANCELLED },
  OPEN: { background: statuses.OPEN },
  NEW: { background: statuses.OPEN },
}));

const JobRequestModal = ({
  handleClose,
  membership_id,
  changeJobStatus,
  sendDataToParent,
  job,
  changeJob,
  getData,
  removeContractor,
}) => {
  const theme = useTheme();
  const [submitting, setSubmitting] = useState(false);
  const classes = useStyles();
  const [membership, setMembership] = useState({});
  const [state, _setState] = useState({
    id: null,
    membership_id: membership_id,
    status: "NEW",
    contact_id: null,
    property_id: null,
    category_id: null,
    code_id: null,
    description: "",
    contact: {},
    property: {},
    assigned: 0,
    estimates_request: [],
  });

  const setState = (values) => _setState({ ...state, ...values });

  useEffect(() => {
    viewMembershipRequest(membership_id).then((res) => {
      setState({
        contact: res.data.members[0],
        contact_id: res.data.members[0].id,
        property: res.data.properties[0],
        property_id: res.data.properties[0].id,
      });
      setMembership(res.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [membership_id]);
  const handleSubmit = (e) => {
    setSubmitting(true);
    e.preventDefault();
    createJobRequest({
      membership_id: membership_id,
      status: state.status,
      contact_id: state.contact_id,
      property_id: state.property_id,
      category_id: state.category_id,
      code_id: state.code_id,
      description: state.description,
      assigned: state.assigned,
    }).then((res) => {
      setState(res.data);
      sendDataToParent(res.data);
      Swal.fire({
        toast: true,
        timer: 4000,
        position: "top-right",
        title: "Job Request Submit Successfully!",
        showConfirmButton: false,
        icon: "success",
        timerProgressBar: true,
        confirmButtonColor: theme.palette.primary.main,
      });
      setSubmitting(false);
      // handleClose();
    });
  };

  return (
    <QModal
      dialogProps={{
        open: true,
        title: `Job request`,
        subtitle: "",
      }}
      handleClose={handleClose}
    >
      <>
        {!membership.membership && (
          <Box
            display="flex"
            height={120}
            justifyContent="center"
            alignItems="center"
          >
            <Box textAlign="center">
              <CircularProgress />
              <Typography>Please wait...</Typography>
            </Box>
          </Box>
        )}
        {membership.membership && (
          <Grid container spacing={2}>
            <Grid item sm={12} md={9}>
              <form onSubmit={handleSubmit}>
                <Members
                  members={membership.members}
                  contact_id={state.contact_id}
                  contact={state.contact}
                  setState={setState}
                />
                <Property
                  property={state.property}
                  property_id={state.property_id}
                  properties={membership.properties}
                  setState={setState}
                />
              </form>
              <Grid item sm={12} md={12}>
                <JobDetails
                  category_id={state.category_id}
                  code_id={state.code_id}
                  description={state.description}
                  setJob={setState}
                  changeJob={changeJob}
                  job={job}
                  state={state}
                  getData={getData}
                  removeContractor={removeContractor}
                />
              </Grid>
              <Box textAlign="center" p={3}>
                <Button
                  disabled={state.id}
                  role="submit"
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  Submit Job Request
                  {submitting && (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <CircularProgress color="success" size={16} />
                    </Box>
                  )}
                </Button>
              </Box>
            </Grid>
            <Grid item sm={4} md={3}>
              <Box mt={2}>
                <Typography
                  variant="subtitle2"
                  className={classes.actionsLabel}
                >
                  Status
                </Typography>
                <FormField
                  select
                  value={state.status}
                  className={classes.whited}
                  classes={{ root: classes[state.status] }}
                  onChange={(e) => {
                    setState({ status: e.target.value });
                    changeJobStatus(e.target.value);
                  }}
                >
                  {state.status === "NEW" && (
                    <MenuItem value={"NEW"}>NEW</MenuItem>
                  )}
                  {statusList.map((status) => (
                    <MenuItem key={status} value={status}>
                      {status}
                    </MenuItem>
                  ))}
                </FormField>
              </Box>
              <Box mt={2}>
                <Typography
                  variant="subtitle2"
                  className={classes.actionsLabel}
                >
                  Assigned To
                </Typography>
                <ApiSelect
                  inputProps={{
                    value: state.assigned,
                    onChange: (e) => setState({ assigned: e.target.value }),
                  }}
                  source={getUsersRequest}
                  reducer="users"
                  itemLabel={"name"}
                  showEmpty
                />
              </Box>
            </Grid>
          </Grid>
        )}
      </>
    </QModal>
  );
};

export default JobRequestModal;
