import React, { useEffect, useState } from "react";
import { Box, CircularProgress, TextField, Autocomplete, Button, MenuItem } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import useReports from "hooks/useReports";
import useContractors from "hooks/useContractors";

const RemittanceReport = () => {
  const {
    reportsReducer: { loading, remittance_data, meta },
    changePage,
    getRemittanceData,
  } = useReports();

  const {
    contractorsReducer: { contractors },
    getAllContractors,
  } = useContractors();

  const [reportResults, setReportResults] = useState([]);
  const [selectedContractors, setSelectedContractors] = useState([]);
  const [, setIsInitialLoad] = useState(true);
  const [searchTriggered, setSearchTriggered] = useState(false);
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");

  const months = [
    { label: "January", value: "01" },
    { label: "February", value: "02" },
    { label: "March", value: "03" },
    { label: "April", value: "04" },
    { label: "May", value: "05" },
    { label: "June", value: "06" },
    { label: "July", value: "07" },
    { label: "August", value: "08" },
    { label: "September", value: "09" },
    { label: "October", value: "10" },
    { label: "November", value: "11" },
    { label: "December", value: "12" },
  ];

  const years = Array.from({ length: 10 }, (_, i) => new Date().getFullYear() - i);

  useEffect(() => {
    getRemittanceData();
    getAllContractors({ no_pagination: true });
    setIsInitialLoad(false);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (Array.isArray(remittance_data) && remittance_data.length > 0) {
      const formattedData = remittance_data.map((report) => {
        const netSale = parseFloat(report?.inv_net_sale) || 0;
        const taxPercentage = parseFloat(report?.tax) || 0;
        const taxAmount = (netSale * taxPercentage) / 100;
        const factoringPercentage = (parseFloat(report?.factor_percent) || 0) * 100;

        return {
          id: report?.id,
          contractor_code: report?.con,
          contractor_name: report?.con_biz ?? "Unassigned",
          invoice_total: `$${report?.total}`,
          invoice_tax: `$${taxAmount.toFixed(2)}`,
          inv_net_sale: `$${report?.inv_net_sale}`,
          invoice_date: report?.inv_date,
          factoring_percentage: `${factoringPercentage.toFixed(0)} %`,
          factoring_amount: `$${report?.factor_amount}`,
          contractor_remittance: `$${report?.con_bill}`,
        };
      });
      setReportResults(formattedData);
    } else if (remittance_data.length === 0) {
      setReportResults([]);
    }
  }, [remittance_data]);

  const handleContractorChange = (event, newValue) => {
    setSelectedContractors(newValue);
  };

  const handleMonthChange = (event) => {
    setMonth(event.target.value);
  };

  const handleYearChange = (event) => {
    setYear(event.target.value);
  };

  const handleSearch = () => {
    const contractorIds = selectedContractors.map((c) => c.id);
    const searchParams = {
      contractor_ids: contractorIds,
      month,
      year,
      page: 1,
    };
    setSearchTriggered(true);
    getRemittanceData(searchParams);
  };

  const handleChangePage = (newPage) => {
    if (searchTriggered) return;

    changePage(newPage);
    const params = { page: newPage + 1, month, year };
    if (selectedContractors.length > 0) {
      params.contractor_ids = selectedContractors.map((c) => c.id);
    }
    getRemittanceData(params);
  };

  const downloadDocument = () => {
    const contractorIds = selectedContractors.length > 0 ? selectedContractors.map((c) => c.id) : [];
    const contractorNames = selectedContractors.map((c) => c.name).join("_").substring(0, 50);
    const filename = `remittance_report_${contractorNames || "all_contractors"}.pdf`;

    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_DOMAIN}/api/v1/contractors/download-remittance-report`,
      responseType: "blob",
      data: { contractor_ids: contractorIds, month, year },
    };

    axios
      .request(config)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };

  const columns = [
    { field: "id", headerName: "Id", width: 50 },
    { field: "contractor_code", headerName: "Code", width: 70 },
    { field: "contractor_name", headerName: "Contractor Name", width: 180 },
    { field: "inv_net_sale", headerName: "Net Sale", width: 100 },
    { field: "invoice_tax", headerName: "Tax", width: 100 },
    { field: "invoice_total", headerName: "Invoice Total", width: 100 },
    { field: "factoring_percentage", headerName: "Service Fee %", width: 100 },
    { field: "factoring_amount", headerName: "Service Fee", width: 150 },
    { field: "contractor_remittance", headerName: "Remittance", width: 120 },
    { field: "invoice_date", headerName: "Invoice Date", width: 200 },
  ];

  return (
    <>
      <Box mb={6} display="flex" alignItems="center" gap={2}>
        <Box sx={{ width: "500px" }}>
          <Autocomplete
            multiple
            options={contractors || []}
            getOptionLabel={(option) => option.name}
            onChange={handleContractorChange}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Select Contractors"
                placeholder="Contractors"
              />
            )}
          />
        </Box>
        <TextField
          select
          label="Month"
          value={month}
          onChange={handleMonthChange}
          variant="outlined"
          sx={{ width: 150 }}
        >
          {months.map((monthOption) => (
            <MenuItem key={monthOption.value} value={monthOption.value}>
              {monthOption.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          select
          label="Year"
          value={year}
          onChange={handleYearChange}
          variant="outlined"
          sx={{ width: 150 }}
        >
          {years.map((yearOption) => (
            <MenuItem key={yearOption} value={yearOption}>
              {yearOption}
            </MenuItem>
          ))}
        </TextField>
        <Button variant="contained" color="primary" onClick={handleSearch}>
          Search
        </Button>
      </Box>
      <Box sx={{ mt: -2, mb: 4 }}>
        <Button variant="contained" color="secondary" onClick={downloadDocument}>
          Download PDF
        </Button>
      </Box>

      {loading ? (
        <CircularProgress style={{ margin: "20px auto", display: "block" }} />
      ) : (
        <Box sx={{ overflow: "auto" }}>
          <DataGrid
            columns={columns}
            rows={reportResults}
            autoHeight
            pageSize={10}
            rowsPerPageOptions={[10]}
            disableSelectionOnClick
            page={meta?.current_page - 1}
            onPageChange={handleChangePage}
            rowCount={meta?.total}
            paginationMode="server"
          />
        </Box>
      )}
    </>
  );
};

export default RemittanceReport;
