import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import useContractors from "hooks/useContractors";
import useAccessControl from "hooks/useAccessControl";
import useSystem from "WebR/hooks/useSystem";
import { parsePhoneNo } from "utils/functions";
import Swal from "sweetalert2";
import { DataGrid } from "@mui/x-data-grid";
import columns from "./ContractorColumnData";
import useStyles from "components/TaskPreview/styles-jss";
export default function ContractorData({ status }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const { canUpdateContractor } = useAccessControl();

  const {
    contractorsReducer: {
      query,         // Regular contractors meta
      activeMeta,      // Active contractors meta
      inactiveMeta,    // Inactive contractors meta
      activeResults,   // Active contractors results
      inactiveResults, // Inactive contractors results
    },
    getContractors,
    getActiveContractors,
    getInactiveContractors,
    changePage,
    changePerPage,
  } = useContractors();

  const selectedMeta = status === "active" ? activeMeta : inactiveMeta;
  const statusResults = status === "active" ? activeResults : inactiveResults;

  const transformedResults = statusResults?.map((contractor) => ({
    id: contractor?.id,
    alpha_ref: contractor?.alpha_ref,
    name: contractor?.name,
    phone: contractor?.phone,
    email: contractor?.email,
    status: contractor?.status,
  }));

  const handleChangePage = (newPage) => {
    changePage(newPage);
  
    if (status === "active") {
      getActiveContractors({
        search: query,
        page: newPage + 1, 
        per_page: selectedMeta?.per_page,
      });
    } else if (status === "inactive") {
      getInactiveContractors({
        search: query,
        page: newPage + 1,
        per_page: selectedMeta?.per_page,
      });
    } else {
      getContractors({
        search: query,
        page: newPage + 1,
        per_page: selectedMeta?.per_page,
      });
    }
  };
  
  const handleChangeRowsPerPage = (event) => {
    changePerPage(event.target.value);
  
    if (status === "active") {
      getActiveContractors({
        search: query,
        page: selectedMeta?.current_page,
        per_page: parseInt(event.target.value),
      });
    } else if (status === "inactive") {
      getInactiveContractors({
        search: query,
        page: selectedMeta?.current_page,
        per_page: parseInt(event.target.value),
      });
    } else {
      getContractors({
        search: query,
        page: selectedMeta?.current_page,
        per_page: parseInt(event.target.value),
      });
    }
  };
  

  
  const { call } = useSystem();
  const callContact = (member, number) => {
    if (localStorage.getItem("status") === "offline") {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-right",
        text: "You Are Not Online!",
        showConfirmButton: false,
        timer: 4000,
        timerProgressBar: true,
      });
    } else if (localStorage.getItem("status") === "online") {
      let phone = parsePhoneNo(number);
      call('+' + phone);
    }
  };

  const renderAction = (data) => (
    <div style={{ display: "flex", gap: "8px", width: "100%" }}>
        <Button
          variant="contained"
          color="primary"
          sx={{ mr: 2.0 }}
          size="small"
          onClick={() => callContact(null, data?.row?.phone )}
        >
          Call
        </Button>
      {canUpdateContractor() &&
        <Button
          variant="contained"
          color="primary"
          sx={{ mr: 2.0 }}
          size="small"
          onClick={() => navigate(`/app/contractors/${data?.id}`)}
        >
          View
        </Button>
      }
    </div>
  );

  return (
    <>
      <DataGrid
        columns={columns(renderAction)}
        rows={transformedResults}
        pageSize={Number(selectedMeta?.per_page)} 
        rowsPerPageOptions={[Number(selectedMeta?.per_page)]}
        onPageChange={handleChangePage}
        onPageSizeChange={handleChangeRowsPerPage}
        paginationMode="server"
        rowCount={selectedMeta?.total}
        page={selectedMeta?.current_page - 1}
        disableSelectionOnClick
        autoHeight
        className={classes.dataGrid}
      />
    </>
  );
}
