import axios from "utils/axios";

const ROLES_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/roles`;
const ROLES_DELETE_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/roles/delete`;

const getRolesRequest = (data) => axios.post(ROLES_ROUTE, data);

const createRolesRequest = (data) => axios.post(`${ROLES_ROUTE}/create`, data);

const updateRolesRequest = (data) => axios.post(`${ROLES_ROUTE}/update`, data);

const deleteRolesRequest = (name) => axios.delete(`${ROLES_DELETE_ROUTE}/${name}`, name);
  
export { getRolesRequest, createRolesRequest, updateRolesRequest, deleteRolesRequest };
