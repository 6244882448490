import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import useInvoices from "hooks/useInvoices";
import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import useStyles from "components/TaskPreview/styles-jss";
import { NavLink, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import {
  addressChange,
  cancleInvoiceRequest,
  editJobNumber,
  getAddress,
  getInvoicedata,
  updateInvoicedata,
} from "api/invoices";
import UpdateInvoice from "./UpdateInvoice";
import { changeStatus } from "api/contractors";

const InvoiceData = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [getInvoiceData, setGetInvoiceData] = useState(null);
  const navigate = useNavigate();
  const [statuses, setStatuses] = useState({});
  const [addresses, setAddresses] = useState([]);
  const [, setAddress] = useState({});
  const {
    invoicesReducer: { results, meta, loading, query },
    searchInvoices,
    changePage,
    changePerPage,
  } = useInvoices();

  useEffect(() => {
    if (results && results.length > 0) {
      const initialStatuses = {};
      results.forEach((inv) => {
        initialStatuses[inv.id] = inv.status || "RECEIVED";
      });
      setStatuses(initialStatuses);
    }
  }, [results]);

  const handleChangePage = (newPage) => {
    changePage(newPage);
    searchInvoices({
      search: query,
      page: newPage + 1,
      per_page: meta ? meta.per_page : 10,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    changePerPage(event);
    searchInvoices({
      search: query,
      page: meta.current_page,
      per_page: parseInt(event.target.value),
    });
  };

  const editInvoiceHandler = async (id) => {
    try {
      const response = await getInvoicedata(id);
      if (response.data) {
        setGetInvoiceData(response?.data?.invoice);
        setOpen(true);
      }
    } catch (error) {
      console.error("Error retrieving invoice:", error);
    } finally {
      // navigate("/app/invoices");
    }
  };

  const handleClick = (data) => {
    const mailtoUrl = `mailto:${data}`;
    window.open(mailtoUrl, "_blank");
  };

  const renderStatus = (data) => {};

  const cancleInvoiceHandler = (invoice_id) => {
    try {
      Swal.fire({
        title: "Cancel Invoice",
        text: "Are you sure you want to cancel this invoice?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        customClass: {
          title: "swal-text",
          popup: "swal-text",
        },
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Cancel it!",
      }).then((result) => {
        if (result.isConfirmed) {
          cancleInvoiceRequest(invoice_id)
            .then(() => {
              Swal.fire({
                toast: true,
                timer: 3000,
                position: "top-right",
                title: "Invoice Canceled Successfully!",
                showConfirmButton: false,
                icon: "success",
              });
            })
            .catch((e) => {});
        }
        searchInvoices(query);
        navigate("/app/invoices");
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  const renderAction = (data) => (
    <div>
      <Button
        size="small"
        color="primary"
        style={{ backgroundColor: "#116089", color: "#fff" }}
        onClick={() =>
          navigate(`/app/viewInvContractorHoc/${data?.id}/${data.id}`)
        }
      >
        View
      </Button>
      <Button
        sx={{ ml: 2 }}
        size="small"
        color="primary"
        style={{ backgroundColor: "orange", color: "#fff" }}
        onClick={() => editInvoiceHandler(data?.id)}
      >
        Edit
      </Button>
      <Button
        sx={{ ml: 2 }}
        size="small"
        color="primary"
        style={{ backgroundColor: "#116089", color: "#fff" }}
        onClick={() => handleClick(data.row.mem_email)}
      >
        Email
      </Button>
      <Button
        sx={{ ml: 2 }}
        size="small"
        color="primary"
        style={{ backgroundColor: "red", color: "#fff" }}
        onClick={() => cancleInvoiceHandler(data.id)}
      >
        Cancel
      </Button>
    </div>
  );

  const transformedResults = results?.map((invoice) => ({
    id: invoice?.id,
    name: invoice.contractor_data.name,
    total: invoice?.total,
    created_at: invoice?.created_at,
    job_number: invoice?.job_number,
    hoc_inv_num: invoice?.hoc_inv_num,
    mem_street: invoice?.mem_street,
    mem_name: invoice?.mem_name,
    mem_email: invoice?.mem_email,
    status: invoice?.status,
    factor_amount: invoice?.factor_amount,
    con_bill: invoice?.con_bill,
    membership_id: invoice?.membership_id,
  }));

  const onSubmit = async (values) => {
    try {
      const response = await updateInvoicedata(values.id, values);
      if (response.data) {
        Swal.fire({
          title: "Are you sure?",
          text: "You would like to make these changes to the invoice?",
          icon: "warning",
          showCancelButton: true,
          customClass: {
            title: "swal-text",
            popup: "swal-text",
          },
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.fire({
              toast: true,
              timer: 4000,
              position: "top-right",
              title: "Contractor Invoice Updated Successfully!",
              showConfirmButton: false,
              icon: "success",
              timerProgressBar: true,
            }).then(() => {
              navigate("/app/invoices");
            });
          }
        });
      }
      searchInvoices(query);
    } catch (error) {
      Swal.fire({
        toast: true,
        position: "top-right",
        icon: "error",
        title: "Something went wrong!",
        timer: 4000,
        showConfirmButton: false,
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/app/invoices");
        }
      });
    }
  };

  const handleChange = (e, id) => {
    const newStatus = e.target.value;
    setStatuses((prevStatuses) => ({ ...prevStatuses, [id]: newStatus }));
    changeStatus(id, newStatus)
      .then((response) => {
        Swal.fire({
          toast: true,
          timer: 4000,
          position: "top-right",
          title: response?.data?.message,
          showConfirmButton: false,
          icon: "success",
          timerProgressBar: true,
        });
        searchInvoices(query);
      })
      .catch((error) => {
        console.error(error);
        Swal.fire({
          position: "top-right",
          timer: 4000,
          icon: "error",
          title: error?.response?.data?.message,
          showConfirmButton: false,
        });
      });
  };

  const handleChangeAddress = (event, membershipId) => {
    const newAddress = event.target.value;
    getAddress(membershipId, newAddress)
      .then((response) => {
        setAddresses(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleAddressChange = (e, id) => {
    const newAddress = e.target.value;
    setAddress((prevAddress) => ({ ...prevAddress, [id]: newAddress }));
    addressChange(id, newAddress)
      .then((response) => {
        Swal.fire({
          toast: true,
          timer: 4000,
          position: "top-right",
          title: response?.data?.message,
          showConfirmButton: false,
          icon: "success",
          timerProgressBar: true,
        });
        searchInvoices(query);
      })
      .catch((error) => {
        console.error(error);
        Swal.fire({
          position: "top-right",
          timer: 4000,
          icon: "error",
          title: error?.response?.data?.message,
          showConfirmButton: false,
        });
      });
  };

  const ConntracrotInvoiceLink = ({ params }) => {
    const navigate = useNavigate();
    const url = `/app/contractors/${params?.id}`;
    const handleLinkClick = (event) => {
      event.preventDefault();
      navigate(url, { replace: true });
    };
    return (
      <NavLink to="#" onClick={handleLinkClick} className="link">
        {params.value}
      </NavLink>
    );
  };

  const MembershipLink = ({ params }) => {
    const navigate = useNavigate();
    const url = `/app/members/${params.id}`;
    const handleLinkClick = (event) => {
      event.preventDefault();
      navigate(url, { replace: true });
    };
    return (
      <NavLink to="#" onClick={handleLinkClick} className="link">
        {params.value}
      </NavLink>
    );
  };

  const handleCellEditCommit = async (newRow) => {
    const newJobNumber = newRow.value;
    const conInvId = newRow.id;
    const data = {
      job_number: newJobNumber,
      con_inv_id: conInvId,
    };
    try {
      const response = await editJobNumber(data);
      if (response.data) {
        Swal.fire({
          toast: true,
          timer: 4000,
          position: "top-right",
          title: response.data.message,
          showConfirmButton: false,
          icon: "success",
          timerProgressBar: true,
        });
      }
    } catch (error) {
      console.error("Error updating job number:", error);
      Swal.fire({
        toast: true,
        timer: 4000,
        position: "top-right",
        title: "Error Updating Job Number",
        showConfirmButton: false,
        icon: "error",
        timerProgressBar: true,
      });
    }
  };

  const columns = (renderStatus, renderAction) => [
    {
      field: "hoc_inv_num",
      headerName: "Invoice Number",
      width: 120,
    },
    {
      field: "job_number",
      headerName: "Job Number",
      width: 100,
      editable: true,
      type: "string",
      renderCell: (params) => (
        <Tooltip title="Click to edit job number">
          <div style={{ cursor: "pointer" }}>{params.value}</div>
        </Tooltip>
      ),
    },
    {
      field: "name",
      headerName: "Contractor Name",
      width: 240,
      editable: true,
      renderCell: (params) => <ConntracrotInvoiceLink params={params} />,
    },
    {
      field: "mem_name",
      headerName: "Member Name",
      width: 130,
      renderCell: (params) => <MembershipLink params={params} />,
    },
    {
      field: "mem_street",
      headerName: "Member Address",
      width: 200,
      editable: true,
      renderCell: (params) => (
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={params.value} // Set the default value to params.value
          onClick={(e) => handleChangeAddress(e, params.row.id)}
          onChange={(e) => handleAddressChange(e, params.row.id)}
          sx={{ width: "100%" }}
        >
          <MenuItem value={params.value}>{params.value}</MenuItem>
          {addresses.map((address) => (
            <MenuItem key={address.uuid} value={address.address}>
              {address.address}
            </MenuItem>
          ))}
        </Select>
      ),
    },
    {
      field: "total",
      headerName: "Total Price",
      sortable: true,
      type: "string",
      width: 100,
    },
    {
      field: "factor_amount",
      headerName: "Factoring Amount",
      type: "string",
      width: 140,
    },
    {
      field: "con_bill",
      headerName: "Contractor Price",
      type: "string",
      width: 140,
    },
    {
      field: "status",
      headerName: "Status",
      type: "string",
      editable: true,
      sortable: true,
      width: 140,
      renderCell: (params) => (
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={statuses[params?.row?.id] || params?.row?.status}
          onChange={(e) => handleChange(e, params?.row?.id)}
          sx={{ width: "100%" }}
        >
          <MenuItem value="RECEIVED">RECEIVED</MenuItem>
          <MenuItem value="PENDING">PENDING</MenuItem>
          <MenuItem value="HOLD">HOLD</MenuItem>
          <MenuItem value="APPROVED">APPROVED</MenuItem>
          <MenuItem value="PAID">PAID</MenuItem>
          <MenuItem value="NOT APPROVED">NOT APPROVED</MenuItem>
        </Select>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      type: "string",
      width: 320,
      sortable: false,
      renderCell: renderAction,
    },
  ];

  return (
    <>
      {open && (
        <UpdateInvoice
          open={open}
          handleClose={() => setOpen(false)}
          onSubmitHandler={onSubmit}
          form={getInvoiceData}
        />
      )}
      {loading ? (
        <CircularProgress style={{ margin: "20px auto", display: "block" }} />
      ) : (
        <Box sx={{ overflow: "auto" }}>
          <DataGrid
            rows={transformedResults}
            columns={columns(renderStatus, renderAction)}
            pageSize={Number(meta?.per_page)}
            rowsPerPageOptions={[Number(meta?.per_page)]}
            onPageChange={handleChangePage}
            onPageSizeChange={handleChangeRowsPerPage}
            paginationMode="server"
            rowCount={meta?.total}
            page={meta?.current_page - 1}
            autoHeight
            className={classes.dataGrid}
            onCellEditCommit={handleCellEditCommit}
          />
        </Box>
      )}
    </>
  );
};
export default InvoiceData;
