import React, { useState } from 'react';
import { Box, CircularProgress, Button } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import TaskPreview from 'components/TaskPreview';
import AssignedTo from "components/AssignedTo";
import Toast from "components/Toast";
import CreateJobModal from "components/CreateJobModal";
import useJobs from "hooks/useJobs"; 
import { NavLink } from 'react-router-dom';

const TaskQueueTab = ({ fetchTasks, taskData, meta, query, loading, changeTaskUser }) => {
    const [openTaskModal, setOpenTaskModal] = useState(false);
    const [openJobModal, setOpenJobModal] = useState(false);
    const [selectedTask, setSelectedTask] = useState(null);
    
    const { getJobsData, jobsReducer } = useJobs(); 

    const fetchJons = () => {
        getJobsData(jobsReducer.query); 
    };

    const taskResults = taskData?.map((task) => ({
        id: task?.id,
        taskable_id: task?.taskable_id,
        title: task?.title,
        member: task?.membership?.members?.[0]?.first_name + " " + task?.membership?.members?.[0]?.last_name || '',
        membership_id: task?.membership_id,
        task_type: task?.task_type,
        taskable: task?.taskable,
        status: task?.status,
        code_id: task?.taskable?.code_id,
        created_at: task?.created_at,
        assigned_to: task?.assigned_to,
        contact: `${task?.taskable?.contact?.first_name} ${task?.taskable?.contact?.last_name}`,
        email: task?.taskable?.contact?.email,
        cell_phone: task?.taskable?.contact?.cell_phone,
        property: `${task?.taskable?.property?.address} ${task?.taskable?.property?.city?.label}`,
        state: `${task?.taskable?.property?.state} ${task?.taskable?.property?.zip}`,
        description: task?.taskable?.description,
    })) || [];

    const NewMembersLink = ({ params }) => {
        const url = `/app/members/${params.row.membership_id}`;
        const handleLinkClick = (event) => {
            event.preventDefault();
            window.open(url, '_blank');
        };
        return (
            <NavLink to="#" onClick={handleLinkClick} className="link">
                {params.value}
            </NavLink>
        );
    };

    const columns = [
        { field: 'member', headerName: 'Member', width: 300, renderCell: (params) => <NewMembersLink params={params} /> },
        { field: 'task_type', headerName: 'Task Type', width: 250 },
        {
            field: 'created_at',
            headerName: 'Date|Time',
            type: "string",
            sortable: false,
            width: 200,
            renderCell: (params) => dayjs(params.value).format("YYYY-MM-DD hh:mm a"),
        },
        {
            field: 'assigned_to',
            headerName: 'Assign to',
            type: "string",
            sortable: false,
            width: 500,
            renderCell: (params) => (
                <AssignedTo
                    value={params?.value ?? 0}
                    onChange={(e) => {
                        changeTaskUser(params?.id, e.target.value);
                        fetchTasks(query);
                        Toast.fire({
                            title: 'Assigned To Updated Successfully!',
                            icon: "success",
                            position: "top-right",
                            timer: 3000,
                        });
                    }}
                />
            ),
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 100,
            renderCell: (params) => (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        setSelectedTask(params);
                        setOpenTaskModal(true);
                    }}
                >
                    View
                </Button>
            ),
        },
    ];

    const handleSwitchtoCreateJob = () => {
        setOpenTaskModal(false);
        setOpenJobModal(true);
    };

    const handleCloseModals = () => {
        setOpenTaskModal(false);
        setOpenJobModal(false);
    };

    return (
        <>
            {loading ? (
                <CircularProgress style={{ margin: '20px auto', display: 'block' }} />
            ) : (
                <Box sx={{ overflow: 'auto' }}>
                    <DataGrid
                        rows={taskResults}
                        getRowId={(row) => row.id}
                        columns={columns}
                        autoHeight
                        pageSize={Number(meta?.per_page || 100)}
                        paginationMode="server"
                        rowCount={meta?.total || 1}
                        page={meta?.current_page - 1 || 1}
                        onPageChange={(newPage) => {
                            fetchTasks({ ...query, page: newPage + 1 });
                        }}
                        onPageSizeChange={(pageSize) => {
                            fetchTasks({ ...query, perPage: pageSize });
                        }}
                        disableSelectionOnClick
                    />
                </Box>
            )}
            {openTaskModal && selectedTask && (
                <TaskPreview
                    getTasks={fetchTasks}
                    data={selectedTask}
                    handleClose={handleCloseModals}
                    handleChange={(updatedTask) => setSelectedTask(updatedTask)}
                    handleSwitchToCreateJob={handleSwitchtoCreateJob}
                />
            )}
            {openJobModal && (
                <CreateJobModal
                    open={openJobModal}
                    handleClose={handleCloseModals}
                    fetchJons={fetchJons} 
                />
            )}
        </>
    );
};

export default TaskQueueTab;
