
import { useDispatch, useSelector } from "react-redux";
import {

  SEARCH_INVOICES_RESULTS,
  CHANGE_PAGE,
  CHANGE_PER_PAGE,
  DELETED_CHECK_RESULTS,
  SEARCH_INVOICES_LOADING,
  GET_OPEN_INVOICE_DATA,
  GET_OPEN_INVOICE
} from "redux/actions/invoicesActions";
import {
  getInvoicesRequest,
  viewInvoiceRequest,
  createInvoiceRequest,
  emailInvoiceRequest,
  downloadInvoiceRequest,
  deletedCheckDataRequest,
  getOpenInvoiceDataRequest,
  getOpenInvoiceContractorRequest
} from "api/invoices";
import { deletedCheckRequestf } from "api/check";

const useInvoices = () => {
  const dispatch = useDispatch();
  const invoicesReducer = useSelector((state) => state?.invoices);

  // const searchInvoices = debounce((query) => {
  //   dispatch({ type: SEARCH_INVOICES, query, loading: true });
  //   getInvoicesRequest(query).then((res) => {
  //     dispatch({ type: SEARCH_INVOICES_RESULTS, results: res?.data });
  //   });
  // }, 600);

  const searchInvoices = (params) => {
    dispatch({ type: SEARCH_INVOICES_LOADING, loading: true });
    return getInvoicesRequest({ ...params })
      .then((res) => {
        dispatch({ type: SEARCH_INVOICES_RESULTS, results: res?.data });
      })
      .finally(() => {
        if (invoicesReducer.loading) {
          dispatch({ type: SEARCH_INVOICES_LOADING, loading: false });
        }
      });
  };

  const getOpenInvoice = (params) => {
    dispatch({ type: SEARCH_INVOICES_LOADING, loading: true });
    return getOpenInvoiceDataRequest({ ...params })
      .then((res) => {
        dispatch({ type: GET_OPEN_INVOICE_DATA, results: res?.data });
      })
      .finally(() => {
        if (invoicesReducer.loading) {
          dispatch({ type: SEARCH_INVOICES_LOADING, loading: false });
        }
      });
  };
  const getCancleInvoiceContractors = (params) => {
    dispatch({ type: SEARCH_INVOICES_LOADING, loading: true });
    return getOpenInvoiceContractorRequest({ ...params })
      .then((res) => {
        dispatch({ type: GET_OPEN_INVOICE, results: res?.data });
      })
      .finally(() => {
        if (invoicesReducer.loading) {
          dispatch({ type: SEARCH_INVOICES_LOADING, loading: false });
        }
      });
  };

  // To get all deleted Member
  const getDeletedCheck = (params) => {
    dispatch({ type: SEARCH_INVOICES_LOADING, loading: true });
    return deletedCheckDataRequest({ ...params })
      .then((res) => {
        dispatch({ type: DELETED_CHECK_RESULTS, results: res.data });
      })
      .finally(() => {
        if (invoicesReducer.loading) {
          dispatch({ type: SEARCH_INVOICES_LOADING, loading: false });
        }
      });
  };

  // To restore deleted Member
  const restoreDeletedCheck = (params) => {
    dispatch({ type: SEARCH_INVOICES_LOADING, loading: true });
    return deletedCheckRequestf({ ...params })
      .then((res) => {
        return getDeletedCheck();
      })
      .finally(() => {
        if (invoicesReducer.loading) {
          dispatch({ type: SEARCH_INVOICES_LOADING, loading: false });
        }
      });
  };

  const viewInvoice = (invId) => viewInvoiceRequest(invId);
  const emailInvoice = (invId) => emailInvoiceRequest(invId);
  const downloadInvoice = (invId) => downloadInvoiceRequest(invId);

  const changePage = (newPage) => {
    dispatch({ type: CHANGE_PAGE, page: newPage + 1 });
  };

  const changePerPage = (event) => {
    dispatch({ type: CHANGE_PER_PAGE, perPage: parseInt(event.target.value) });
  };

  const crateInvoice = (params) => { return createInvoiceRequest(params) };

  return {
    invoicesReducer,
    searchInvoices,
    crateInvoice,
    viewInvoice,
    changePerPage,
    changePage,
    emailInvoice,
    downloadInvoice,
    getDeletedCheck,
    restoreDeletedCheck,
    getOpenInvoice,
    getCancleInvoiceContractors
  };
};
export default useInvoices;