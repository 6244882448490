import React, { useEffect, useState } from "react";
import { Tab, Box, Button, Typography } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import PaperBlock from "components/PaperBlock";
import useApplication from "hooks/useApplication";
import Member from "./components/Members";
import DeletedMembers from "./components/DeletedMembers";
import SearchInput from "components/SearchInput";
import useMembership from "hooks/useMembership";
import useAccessControl from "hooks/useAccessControl";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import LoadingButton from "components/LoadingButton";
import FormField from "components/FormField";
import NewMember from "./components/NewMember";
import { checkZipRequest } from "api/utilities";
import { useLocation } from "react-router-dom";

const Members = () => {
  const [tabValue, setTabValue] = useState("0");
  const [zip, setZip] = useState("");
  const [zipLoading, setZipLoading] = useState(false);
  const [zipMessage, setZipMessage] = useState("");
  const location = useLocation();

  useEffect(() => {
    if (location.state?.tabValue) {
      setTabValue(location.state.tabValue);
    }
  }, [location.state]);

  const { setPage } = useApplication();
  const { canCreateContractor } = useAccessControl();

  const {
    getMemberships,
    getOpenMember,
    getDeletedMembers,
    membershipReducer: { query, meta },
  } = useMembership();

  const navigate = useNavigate();

  useEffect(() => {
    setPage({
      title: "Members Directory",
      path: "/app/members",
    });
    // eslint-disable-next-line
  }, []);

  const handleSearch = (search) => {
    getMemberships({
      search,
      per_page: meta?.per_page,
    });
    getOpenMember({
      search,
      per_page: meta?.per_page,
    });
    getDeletedMembers({
      search,
      per_page: meta?.per_page,
    });
  };

  const checkZip = () => {
    if (zip) {
      setZipLoading(true);
      setZipMessage("");
      checkZipRequest({ zip })
        .then(() => {
          setZipMessage(`Your zip code is located in an area that we service.`);
          setZipLoading(false);
        })
        .catch(() => {
          setZipMessage(
            "Your zip code is not located in an area that we can service."
          );
          setZipLoading(false);
        });
    }
  };

  const setZipCode = (zip) => {
    setZipMessage("");
    setZip(zip);
  };

  return (
    <>
      <PaperBlock
        title="Members"
        actions={
          <Box display="flex" justifyContent="space-between" alignItems="center" className="memberSearchBox">
            {/* Left Section: Zip Code and Search */}
            <Box display="flex" alignItems="center" gap={2}>
              {/* Zip Code Input */}
              <Box display="flex" alignItems="center" gap={1}>
                <FormField
                  value={zip}
                  onChange={(e) => setZipCode(e.target.value)}
                  size="small"
                  label="Zip Code"
                  sx={{ width: "150px" }}
                />
                <LoadingButton
                  loading={zipLoading}
                  onClick={checkZip}
                  variant="contained"
                  color="primary"
                  size="small"
                >
                  Check
                </LoadingButton>
              </Box>

              {/* Search Input */}
              <SearchInput
                defaultValue={query}
                onChange={handleSearch}
                placeholder="By ID, First Name, Last Name, Phone or Email"
              />
            </Box>

            {/* Right Section: Add Member Button */}
            {canCreateContractor() && (
              <Button
                sx={{ whiteSpace: "nowrap", minWidth: "auto" }}
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => navigate("/app/members/create")}
              >
                Add Member
              </Button>
            )}
          </Box>
        }
      >
        {/* Zip Code Message */}
        {zipMessage && (
          <Box sx={{ mt: 2 }}>
            <Typography
              variant="subtitle2"
              color={zipMessage.includes("is located") ? "green" : "red"}
            >
              {zipMessage}
            </Typography>
          </Box>
        )}

        <Box sx={{ flexGrow: 1, bgcolor: "background.paper", mt: 2 }}>
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={(_event, newValue) => setTabValue(newValue)}>
                <Tab label="Members" value="0" />
                <Tab label="New Members" value="1" />
                <Tab label="Canceled Members" value="2" />
              </TabList>
            </Box>
            <TabPanel value="0">
              <Member />
            </TabPanel>
            <TabPanel value="1">
              <NewMember />
            </TabPanel>
            <TabPanel value="2">
              <DeletedMembers />
            </TabPanel>
          </TabContext>
        </Box>
      </PaperBlock>
    </>
  );
};

export default Members;
