import { NavLink, useNavigate } from 'react-router-dom';
import "../../../../../../src/components/Callincoming/index.css"
import { Chip } from '@mui/material';
import { membershipColors } from "utils/membership";


const ContractorLink = ({ params }) => {
    const navigate = useNavigate();
    const url = `/app/contractors/${params.id}`;
    const handleLinkClick = (event) => {
        event.preventDefault();
        navigate(url, { replace: true });
    };

    return (
        <NavLink to="#" onClick={handleLinkClick} className='link'>
            {params.value}
        </NavLink>
    );
};

const columns = (renderAction) => [
    {
        field: "alpha_ref",
        headerName: "Code",
        sortable: true,
        type: "string",
        flex: 1, 
        renderCell: (params) => <ContractorLink params={params} />,
    },
    {
        field: 'name',
        headerName: 'Contractor Name',
        flex: 2,
        editable: true,
        renderCell: (params) => <ContractorLink params={params} />,
    },
    {
        field: 'phone',
        headerName: 'Phone',
        flex: 1.5, 
        editable: true,
    },
    {
        field: 'email',
        headerName: 'Email',
        flex: 3,
        editable: true,
    },
    {
        field: 'status',
        headerName: 'Status',
        flex: 1.5,
        renderCell: (params) => (
              <Chip
                label={params.value}
                style={{ background: membershipColors[params.value], color: "white" }}
              />
          )
    },
    {
        field: "action",
        headerName: "Action",
        type: "string",
        flex: 3,
        sortable: false,
        renderCell: renderAction,
    },
];

export default columns;