import React, { useEffect, useState } from "react";
import { Box, Tab } from "@mui/material";
import useApplication from "hooks/useApplication";
import useInvoices from "hooks/useInvoices";
import PaperBlock from "components/PaperBlock";
import SearchInput from "components/SearchInput";
import Filter from "../components/Filter";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import InvoiceData from "./components/InvoiceData";
import OpenInvoiceData from "./components/OpenInvoiceColumnData";
import OpenContractorInvoiceColumnData from "pages/App/Contractors/Index/components/OpenContractorInvoiceColumnData";
import { useLocation } from "react-router-dom";

export default function Invoices() {
  const [tabValue, setTabValue] = useState("0");
  const location = useLocation();

  useEffect(() => {
    if (location.state?.tabValue) {
      setTabValue(location.state.tabValue);
    }
  }, [location.state]);

  const { setPage } = useApplication();
  const {
    invoicesReducer: { filter, search, meta },
    searchInvoices,
    getOpenInvoice,
    getCancleInvoiceContractors
  } = useInvoices();

  useEffect(() => {
    setPage({
      title: "Invoices",
      path: "/app/invoices",
    });
    searchInvoices()
    // eslint-disable-next-line
  }, []);

  const handleSearch = (value) => {
    searchInvoices({
      search: value,
      page: meta?.current_page,
      per_page: meta?.per_page,
    });
    getOpenInvoice({
      search: value
    });
    getCancleInvoiceContractors({
      search: value
    })
  };

  const handleFilter = (values) => {
    searchInvoices({
      filter: values,
      search,
      page: meta?.current_page,
      per_page: meta?.per_page,
    });
  };
  const handleTabChange = (event, newValue) => {  
    setTabValue(newValue);  
    searchInvoices({  
     search,  
     page: meta?.current_page,  
     per_page: meta?.per_page,  
    });  
   };

  return (
    <PaperBlock
      title="Invoices"
      actions={
        <>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            className="memberSearchBox"
          >
            <Filter values={filter} onChange={handleFilter} />
            <SearchInput
              defaultValue={search}
              onChange={handleSearch}
              placeholder="By ID, First Name, Last Name, Phone or Email"
            />
          </Box>
        </>
      }
    >
      <Box sx={{ flexGrow: 1, bgcolor: "background.paper" }}>
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList  onChange={handleTabChange}>
              <Tab label="Invoices" value="0" />
              <Tab label="Open Invoices" value="1" />
              <Tab label="Canceled Invoices" value="2" />
            </TabList>
          </Box>
          <TabPanel value="0">
            <InvoiceData />
          </TabPanel>
          <TabPanel value="1">
            <OpenInvoiceData />
          </TabPanel>
          <TabPanel value="2">
            <OpenContractorInvoiceColumnData />
          </TabPanel>
        </TabContext>
      </Box>
    </PaperBlock>
  );
}
