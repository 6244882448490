import axios from "utils/axios";
import { DELETED_MEMBER, DELETED_MEMBERT, DELETED_MEMBERTV } from "./routes";

const MEMBERSHIP_UPDATE_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/membership/update`;
const SAVE_APPLICATION_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/membership/create`;
const MEMBERSHIP_PROPERTY_UPDATE_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/membership/property/update`;
const MEMBERSHIP_PROPERTY_CREATE_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/membership/property/create`;
const MEMBERSHIP_PROPERTY_DELETE_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/membership/property/delete`;
const SAVE_APPLICATION_WEB_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/membership/create-web`;
const MEMBERSHIP_SEARCH_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/membership`;
const MEMBERSHIP_SHOW_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/membership`;
const GET_MEMBERSHIP_CONTRACTORS_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/membership/contractors`;
const CHANGE_MEMBERSHIP_STATE_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/membership/change-state`;
const NEW_MEMBER = `${process.env.REACT_APP_API_DOMAIN}/api/v1/membership/new-members`;
export const DELETE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/membership/delete`;
const MEMBER_INVOICE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/jobs/member-job-data`;

const updateMembershipRequest = (data) =>
  axios.post(MEMBERSHIP_UPDATE_ROUTE, data);

const updateMembershipPropertyRequest = (data) =>
  axios.post(MEMBERSHIP_PROPERTY_UPDATE_ROUTE, data);

const createMembershipPropertyRequest = (data) =>
  axios.post(MEMBERSHIP_PROPERTY_CREATE_ROUTE, data );

const deleteMembershipPropertyRequest = (data) =>
  axios.post(MEMBERSHIP_PROPERTY_DELETE_ROUTE, data);

const saveApplicationRequest = (data) =>
 axios.post(SAVE_APPLICATION_ROUTE, data,{
    headers: { 'Content-Type': 'application/json' }
  });

export const getMemberInvRequest = (id) =>
  axios.get(`${MEMBER_INVOICE}/${id}`);

const saveApplicationWebRequest = (data) =>
  axios.post(SAVE_APPLICATION_WEB_ROUTE, data);

const searchRequest = (search) =>
  axios.get(MEMBERSHIP_SEARCH_ROUTE, { params: search });

const viewMembershipRequest = (id) =>
  axios.get(`${MEMBERSHIP_SHOW_ROUTE}/${id}`);

const changeStateRequest = (id, data) =>
  axios.post(`${CHANGE_MEMBERSHIP_STATE_ROUTE}/${id}`, data);

const getMembershipContractorsRequest = (membership_id) =>
  axios.get(`${GET_MEMBERSHIP_CONTRACTORS_ROUTE}/${membership_id}`);

const DeletedMemberRequest = (data) => axios.post(DELETED_MEMBER,data);
const DeletedMemberRequestv = (data) => axios.delete(DELETED_MEMBERT, {params:data});
const DeletedMemberRequestf = (data) => axios.post(DELETED_MEMBERTV, data);
const newMemberRequest = (data, pagination) => {
  return axios.get(NEW_MEMBER, {
    params: { ...data, ...pagination },
  });
};

export const deleteMemberRequest = (id) =>
  axios.delete(`${DELETE}/${id}`);

export {
  saveApplicationRequest,
  searchRequest,
  viewMembershipRequest,
  updateMembershipRequest,
  updateMembershipPropertyRequest,
  createMembershipPropertyRequest,
  deleteMembershipPropertyRequest,
  changeStateRequest,
  saveApplicationWebRequest,
  getMembershipContractorsRequest,
  DeletedMemberRequest,
  DeletedMemberRequestv,
  DeletedMemberRequestf,
  newMemberRequest
  // saveCallDataRequest
};
