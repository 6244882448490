import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Tabs, Tab, Grid } from "@mui/material";
import ContentBlock from "components/ContentBlock";
import useApplication from "hooks/useApplication";
import useUser from "hooks/useUser";
import useAuth from "hooks/useAuth";
import {
  ChangePassword,
  AccountDetails,
  TasksColor,
  MfaSetup,
  Settings,
} from 'components/UserProfile';
import useAccessControl from "hooks/useAccessControl";

export default function View() {
  const { setPage } = useApplication();
  const { getUser } = useUser();
  const { user: userData } = useAuth();
  const { isAdmin } = useAccessControl();
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState({});
  const [selectedTab, selectTab] = React.useState(0);

  useEffect(() => {
    setPage({
      title: "Users",
      path: "/app/users",
    });
    setLoading(true);
    if (userData?.data?.uuid) {
      getUser(userData.data.uuid)
        .then((resp) => {
          if (resp?.data !== undefined) {
            setUser(resp.data);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const handleMFAChange = (mfa) => {
    setUser({
      ...user,
      mfa,
    });
  }

  return (
    <ContentBlock title="Users" icon="ion-ios-clipboard-outline">
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress color="primary" size={40} />
        </Box>
      ) : (
        <Grid container spacing={4}>
          <Grid item sm={12} md={2}>
            <Tabs
              orientation="vertical"
              value={selectedTab}
              onChange={(_event, newValue) => selectTab(newValue)}
              sx={{ borderRight: 1, borderColor: 'divider', height: '100%' }}
            >
              <Tab label="Account details" />
              <Tab label="Change password" />
              <Tab label="Tasks colors" />
              <Tab label="MFA setup" />
              <Tab label="Settings" />
              {isAdmin && <Tab label="QuickBooks Connect" />}
            </Tabs>
          </Grid>
          <Grid item  md={10}>
            {selectedTab === 0 && <AccountDetails user={user} />}
            {selectedTab === 1 && <ChangePassword user={user}/>}
            {selectedTab === 2 && <TasksColor user={user} />}
            {selectedTab === 3 && <MfaSetup users={user} onChangeCallback={handleMFAChange} />}
            {selectedTab === 4 && <Settings user={user} />}
            {/* {isAdmin && selectedTab === 5 && <QuickBooksConnect user={user} />} */}
          </Grid>
        </Grid>
      )}
    </ContentBlock>
  );
}
