import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";

import { Button, Chip } from "@mui/material";
import { invoiceStatusColors } from "utils/statusColors";
import { useNavigate } from "react-router-dom";

import columns from "./tableData";
import useStyles from "./styles";
import useAccessControl from "hooks/useAccessControl";

const Invoices = ({ invoices }) => {
  const classes = useStyles();
  const { canViewInvoice } = useAccessControl();
  const [invoicesData, setInvoicesData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setInvoicesData(invoices);
  }, [invoices]);

  const handleOpen = (row) => {
    if (row.status === "PAID" || row.status === "ISSUED") {
      navigate(`/app/invoices/${row.id}`);
    } else {
      navigate(`/app/members`);
    }
  };

  const renderStatus = (data) => {
    return (
      <Chip
        sx={{
          background: invoiceStatusColors[data.row.status],
          color: "white",
        }}
        label={data.row.status}
      />
    );
  };

  const handleClick = (data) => {    
    const mailtoUrl = `mailto:${""}`;
    window.open(mailtoUrl, "_blank");
  };

  const renderAction = (data) => (
    <div>
      <Button size="small" onClick={() => handleOpen(data.row)}>
      {(data.row.status === "PAID" && canViewInvoice()) ||
      data.row.status === "ISSUED" || data.row.status === "REFUND"
        ? "View"
        : ""}
    </Button>
      <Button onClick={() => handleClick(data)} size="small">Email</Button>
    </div>
  );

  return (
    <React.Fragment>
      <DataGrid
        rows={invoicesData}
        columns={columns(renderStatus, renderAction)}
        disableSelectionOnClick
        pageSize={13}
        rowsPerPageOptions={[13]}
        autoHeight
        className={classes.dataGrid}
        density="compact"
      />
    </React.Fragment>
  );
};

export default Invoices;
