import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import FormField from "components/FormField";
import CallableField from "components/CallableField";
import { useFormik } from "formik";

const MemberUpdate = ({
  member,
  onChange,
}) => {
  const formik = useFormik({
    initialValues: {
      id: member.id || undefined,
      first_name: member.first_name,
      last_name: member.last_name,
      email: member.email,
      home_phone: member.home_phone,
      cell_phone: member.cell_phone,
      work_phone: member.work_phone,
      work_phone_extension: member.work_phone_extension,
      occupation: member.occupation,
      company: member.company,
    }
  });

  useEffect(() => {
    formik.setValues({
      ...formik.values,
      id: member.id || undefined,
      first_name: member.first_name,
      last_name: member.last_name,
      email: member.email,
      home_phone: member.home_phone,
      cell_phone: member.cell_phone,
      work_phone: member.work_phone,
      work_phone_extension: member.work_phone_extension,
      occupation: member.occupation,
      company: member.company,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [member]);

  const handleOnChange = async (e) => {
    const newValues = formik.values;
    newValues[e.target.name] = e.target.value;
    onChange(newValues);
  };

  return (
    <>
      <Grid item md={3} sm={12} xs={12}>
        <FormField
          label="First Name"
          value={formik.values.first_name || ""}
          onChange={handleOnChange}
          name="first_name"
          required
        />
      </Grid>
      <Grid item md={3} sm={12} xs={12}>
        <FormField
          label="Last Name"
          value={formik.values.last_name || ""}
          onChange={handleOnChange}
          name="last_name"
          required
        />
      </Grid>
      <Grid item md={6} sm={12} xs={12}>
        <FormField
          label="Email"
          value={formik.values.email || ""}
          onChange={handleOnChange}
          name="email"
          type="email"
        />
        {/* <EmailField
          label="Email"
          value={formik.values.email}
          onChange={handleOnChange}
          defaultValue={formik.values.email}
          name="email"
          type="email"
          required
        /> */}

      </Grid>
      <Grid item md={3} sm={12} xs={12}>
        <CallableField
          value={formik.values.cell_phone || ""}
          onChange={handleOnChange}
          label="Primary Phone"
          name="cell_phone"
          required
        />
      </Grid>
      <Grid item md={3} sm={12} xs={12}>
        <CallableField
          value={formik.values.work_phone || ""}
          label="Secondary Phone"
          name="work_phone"
          onChange={handleOnChange}
        />
      </Grid>
      <Grid item md={3} sm={12} xs={12}>
        <FormField
          label="Extension"
          name="work_phone_extension"
          onChange={handleOnChange}
          value={formik.values.work_phone_extension || ""}
        />
      </Grid>
      <Grid item md={3} sm={12} xs={12}>
        <FormField
          label="Occupation"
          name="occupation"
          value={formik.values.occupation || ""}
          onChange={handleOnChange}
        />
      </Grid>
      <Grid item md={3} sm={12} xs={12}>
        <FormField
          label="Company"
          name="company"
          value={formik.values.company || ""}
          onChange={handleOnChange}
        />
      </Grid>
    </>
  );
};

export default MemberUpdate;
