import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle
} from "react";
import {
  TableContainer,
  Button,
  CircularProgress,
} from "@mui/material";
import useRoles from "hooks/useRoles";
import RolesManagementDialog from "./RolesManagementDialog";
import useUtils from "hooks/useUtils";
import useAccessControl from "hooks/useAccessControl";
import { deleteRolesRequest } from "api/roles";
import Swal from "sweetalert2";
import { DataGrid } from "@mui/x-data-grid";

const RolesList = forwardRef((_props, ref) => {
  const { getRoles } = useRoles();
  const { utils } = useUtils();
  const [roles, setRoles] = useState([]);
  const [selectedRole, selectRole] = useState(null);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const { canUpdateRole, canDeleteRole } = useAccessControl();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  useImperativeHandle(ref, () => ({
    setRoles: (roles) => setRoles(roles),
    roles,
  }));

  useEffect(() => {
    getRoles({
      page: page + 1,
      per_page: rowsPerPage,
    }).then((res) => {
      setRoles(res.data);
      setLoading(false);
    });
    // eslint-disable-next-line
  }, [page, rowsPerPage]);

  useEffect(() => {
    getRoles().then((res) => {
      setRoles(res.data);
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    selectRole(null);
    setOpen(false);
  };

  const handleOpen = (roleId) => {
    setOpen(true);
    selectRole(roleId);
  };

  const handledelete = (roleName) => {
    Swal.fire({
      title: "Delete Role?",
      text: "You won't be able to revert this!",
      icon: "warning",
      customClass: {
        title: "swal-text",
        popup: "swal-text"
      },
      fontFamily: "Open Sans",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        deleteRolesRequest(roleName)
          .then(() => {
            Swal.fire({
              toast: true,
              timer: 3000,
              position: "top-right",
              title: 'Role Deleted Successfully!',
              showConfirmButton: false,
              icon: "success"
            })
            getRoles().then((res) => {
              setRoles(res.data);
              setLoading(false);
            });
          })
          .catch((e) => { setLoading(false); });
      }
    });

  };

  const rolesResults = roles?.original?.result?.map((rolesData, index) => {
    return {
      id: rolesData.id,
      permissions: rolesData.permissions,
      name: rolesData.name,
      serialNumber: index + 1,
    };
  });

  const renderAction = (data) => (
    <div>
      {
        canUpdateRole() &&
        <Button onClick={() => handleOpen(data.row.id)} variant="contained"
          color="primary"
          size="small" sx={{ mr: 2 }}>
          Edit
        </Button>
      }
      {canDeleteRole() && !["Accounting", "Customer Service", "Executive"].includes(data.row.name) ?
        <Button onClick={() => handledelete(data.row.name)} variant="contained"
          color="error"
          size="small">
          Delete
        </Button> :
        ""
      }
    </div>
  );

  const columns = (renderAction) => [
    {
      field: 'serialNumber',
      headerName: 'NO.',
      width: 300,
      renderCell: (params) => (
        <div>
          {params.row.serialNumber}
        </div>
      ),
    },
    {
      field: 'id',
      headerName: 'Name',
      width: 450,
      editable: true,
    },
    {
      field: 'permissions', // assuming you have a 'permissions' field in your data
      headerName: 'Permissions',
      width: 450,
      editable: true,
      renderCell: (params) => (
        <div>
          {`${params.row.permissions?.length} / ${utils?.permissions?.length}`}
        </div>
      ),
    },
    {
      field: 'action',
      headerName: 'Action',
      sortable: false,
      // type:"number",
      width: 200,
      renderCell: renderAction,
    },
  ];
const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <TableContainer sx={{ width: "100%" }}>
        {loading ? ( // Display loader when data is being fetched
          <CircularProgress style={{ margin: "20px auto", display: "block" }} />
        ) : (
          <DataGrid
            columns={columns(renderAction)}
            rows={rolesResults}
            paginationMode="server"
            rowCount={roles?.original?.meta?.total}
            page={page}
            pageSize={rowsPerPage}
            onPageChange={handleChangePage}
            onPageSizeChange={handleChangeRowsPerPage}
            autoHeight
          />
        )}
      </TableContainer>
      <RolesManagementDialog
        setRoles={setRoles}
        open={open}
        role={roles?.original?.result?.find((role) => role.id === selectedRole)}
        onClose={handleClose}
      />
    </>
  );
});
export default RolesList;